import React from "react";
import myImage from "../img/myImage.png";

class Involvement extends React.Component {
  constructor() {
    super();
    this.state = {
      skills: [

      ],
      about_me: [

      ]
    };
  }

  render() {
    return (
      <section id="involvement" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-12">
                    <div className="title-box-2">
                      <h5 className="title-left">Involvement</h5>
                    </div>

                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "25px"}}>Red Shoe Crew, <strong style={{color: "#0078ff"}}>President</strong></div>
                        <div><i style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>Ronald McDonald House Charities - Lexington, KY<span> - Fall 18-Current</span></i></div>
                    <ul>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Red Shoe Crew is a Student Organization of ~100 members whose mission is to serve the Ronald McDonald House</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Facilitating weekly volunteering events (10+ hours weekly) and fundraising/donating ($1,000+) for RMH each semester</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Developing leadership skills while serving a community of wonderful people in need</div>
                      </li>
                    </ul>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "25px"}}>DanceBlue, <strong style={{color: "#0078ff"}}>Operations Committee</strong></div>
                    <div><i style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>University of Kentucky Pediatric Cancer Clinic - Lexington, KY<span> - 2019-Current</span></i></div>
                    <ul>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>DanceBlue is a 24-hour dance marathon fundraiser for Pediatric Cancer Research - one of the biggest in the nation</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Help organize and execute the DanceBlue 5K in the fall and the 24-hour DanceBlue marathon in the spring</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Raised over $2 million in 2019-20 and over $1 million in 2020-21</div>
                      </li>
                    </ul>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "25px"}}>Sigma Chi Fraternity</div>
                    <div><i style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>University of Kentucky - Lexington, KY</i></div>
                    <ul>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Served on Executive Committee and help organize various volunteering events</div>
                      </li>
                    </ul>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "25px"}}>Intramural Sports</div>
                    <div><i style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>University of Kentucky - Lexington, KY</i></div>
                    <ul>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Flag Football, Basketball, and Soccer</div>
                      </li>
                    </ul>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Involvement;
