import React from "react";
import myImage from "../img/myImage.png";

class Experience extends React.Component {
  constructor() {
    super();
    this.state = {
      skills: [

      ],
      about_me: [

      ]
    };
  }

  render() {
    return (
      <section id="experience" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-12">
                    <div className="title-box-2">
                      <h5 className="title-left">Experience</h5>
                    </div>

                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "25px"}}>Digital Technology Internship, <strong style={{color: "#0078ff"}}>Data Engineer</strong></div>
                        <div><i style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>General Electric (GE) Aviation - Cincinnati, OH<span> - Summer 2021</span></i></div>

                    <ul>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}><strong>Data Engineer</strong> tracking Supply Chain Engine Modernization data from various sources to final reports</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Acted as a Product Owner for data lineage and <strong>iteratively meet</strong> with necessary teams to discuss requirements</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Mapped, connected, and documented dependencies within data to build a <strong>scalable and portable</strong>solution</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Extracted, Transformed, and Loaded (<strong>ETL</strong>) data using HVR, DBeaver, and eventually Spotfire</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}><strong>Engineered</strong> metadata and modified <strong>Python scripts</strong> for data parsing and then create views from SQL queries</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Led intern class as <strong>Staff Focal</strong> by leading staff meetings, acting as point of contact, and guiding class through summer</div>
                      </li>
                    </ul>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "25px"}}>College of Business, <strong style={{color: "#0078ff"}}>Student Web Coordinator</strong></div>
                    <div><i style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>University of Kentucky - Lexington, KY<span> - Academic Year 2020-21</span></i></div>
                    <ul>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Web Developer designing and implementing webpages in <strong>HTML/CSS</strong></div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Maintained structures of entities, taxonomies of people, views of webpages, etc., through <strong>Drupal</strong></div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Utilized <strong>Google Analytics</strong> for analyzing usage data to follow/predict trends</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}><strong>Collaborated</strong> with customers and stakeholders to deliver accurate information and resource</div>
                      </li>
                    </ul>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "25px"}}>Digital Technology Internship, <strong style={{color: "#0078ff"}}>Software Developer</strong></div>
                    <div><i style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>General Electric (GE) Aviation - Cincinnati, OH<span> - Summer 2020</span></i></div>
                    <ul>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Software Engineer in Fleet Diagnostics (SOAR) supporting the migration to AWS</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Made <strong>business decisions</strong> in best path moving forward away from decommissioned tools (<strong>Jenkins</strong>)</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Drove transition to <strong>AWS</strong> resources for Continuous Integration/Deployment (<strong>CI/CD</strong>) Pipeline to AWS Fargate</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Spun up <strong>Codebuild Projects</strong> in AWS for Continuous Integration and developed configuration files for projects</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Helped validate engine data between Prod/NonProd environments</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Worked in <strong>Agile</strong> environment and acted as Scrum Master for our backend development team</div>
                      </li>
                    </ul>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "25px"}}>Information Technology Internship, <strong style={{color: "#0078ff"}}>Developer</strong></div>
                    <div><i style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>PublicSchoolWORKS, WORKS Int’l - Cincinnati, OH<span> - Summer 2019</span></i></div>
                    <ul>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Updated and developed client portals via KompoZer, an open source <strong>HTML</strong> editor</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Created and organized district specific files through Custom Server Programs and Adobe Acrobat</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Analyzed <strong>data, research, and district information</strong> from Salesforce via Excel (Macros)</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Maintained and customized a functional and easily accessible <strong>user interface</strong> for district’s websites and files</div>
                      </li>
                      <li>
                        <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px"}}>Spoke with clients about their <strong>user experience</strong> to learn more about their needs/wishes</div>
                      </li>
                    </ul>
                    </div>
                    <div className="col-md-6">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Experience;
