import React from "react";
import myImage from "../img/myImage.png";

class Skills extends React.Component {
  constructor() {
    super();
    this.state = {
      skills: [
        { id: "WebDev_skill", content: "Web Development", porcentage: "85%", value: "70" },
        { id: "SoftwareEng_skill", content: "Software Engineering", porcentage: "55%", value: "70" },
        { id: "Cloud_skill", content: "Cloud Computing", porcentage: "80%", value: "70" },
        { id: "DataEng_Skill", content: "Data Engineering", porcentage: "75%", value: "70" },
        { id: "PO_skill", content: "Product Ownership", porcentage: "65%", value: "70" },
      ]
    };
  }

  render() {
    return (
      <section id="skills" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-9">
                    <div className="skill-mf">
                      <div className="title-box-2">
                        <h5 className="title-left">Skills</h5>
                      </div>
                      {this.state.skills.map(skill => {
                        return (
                          <React.Fragment key={skill.id}>
                            <span>{skill.content}</span>{" "}
                            <span className="pull-right">

                            </span>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: skill.porcentage }}
                                aria-valuenow={skill.value}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <br></br>


                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "23px"}}><strong>Languages</strong></div>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>- Python</div>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>- C/C++</div>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>- HTML/CSS</div>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>- SQL</div>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>- MATLAB</div>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>- Javascript</div>
                    <br></br>



                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "23px"}}><strong>Business Skills</strong></div>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>- Leadership</div>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>- Self-Awareness</div>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>- Communication</div>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>- Goals Oriented</div>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>- Interpersonal</div>
                    <div style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}>- Agile and Scrum</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;
