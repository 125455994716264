import React from "react";
import myImage from "../img/myImage.png";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      about_me: [
        {
          id: "first-p-about",
          content:
            "Growing up a middle child of five kids engraved the values of independence and competition in me.  School and sports instilled in me leadership and never being content.  My very first iPhone 3G sparked a passion for technology and a curiosity about how things work.  I'm a problem solver with leadership experience, advanced interpersonal and communication skills, and an internal drive to keep pushing forward. I want to be apart of the continual technological advancements that make the world a better, safer, and faster place for everyone."
        }
      ],
      city: [
        {
          id: "city_id",
          content: "Cincinnati, OH"
        }
      ],
      phone: [
        {
          id: "phone_id",
          content: "859.907.3800"
        }
      ],
      email: [
        {
          id: "email_id",
          content: "carterkunstek@gmail.com"
        }
      ],
      resume: [
        {
          id: "resume_id",
          content: "Hometown: Florence, KY"
        }
      ]
    };
  }

  render() {
    return (
      <section id="about" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
              <div className="row">
                <div className="col-md-8">
                  <div className="about-me pt-4 pt-md-0">
                    <div className="title-box-2">
                      <h5 className="title-left">Education</h5>
                    </div>
                    <p style={{fontFamily: "Patrick Hand SC", fontSize: "23px"}}><strong><span style={{color: "#0078ff"}}>Computer Science</span></strong>, Bachelor of Science</p>
                    <p style={{fontFamily: "Patrick Hand SC", fontSize: "18px", color: "#0078ff"}}><i>University of Kentucky, College of Engineering</i></p>
                    <p style={{fontFamily: "Patrick Hand SC", fontSize: "15px"}}>Relevant Coursework: Web Development, Databases, Cryptology, Artificial Intelligence, Machine Learning</p>
                  </div>
                </div>
                <div className="col-md-4">
                <div className="title-box-2">
                  <h5 className="title-left">Details</h5>
                </div>

                  <ul>
                    <li>
                      <p style={{fontFamily: "georgia"}}>Projected Graduation: <span style={{color: "#0078ff"}}>May 2022</span></p>
                    </li>
                    <li>
                      <p style={{fontFamily: "georgia"}}>GPA: <span style={{color: "#0078ff"}}>3.7</span></p>
                    </li>
                    <li>
                      <p style={{fontFamily: "georgia"}}>Presidential Scholarship Recipient</p>
                    </li>
                    <li>
                      <p style={{fontFamily: "georgia"}}>Dean's Engineering Leadership Class</p>
                    </li>
                  </ul>
                </div>
                </div>

                <div className="row">
                  <div className="col-md-8">
                    <div className="about-me pt-4 pt-md-0">
                      <div className="title-box-2">
                        <h5 className="title-left">About Me</h5>
                      </div>
                      <p style={{fontFamily: "verdana"}}> Growing up a middle child of five kids engraved the values of independence and competition in me.  School and sports instilled in me leadership and never being content.  My very first iPhone 3G sparked a passion for technology and a curiosity about how things work.  I'm a <strong>problem solver </strong>with leadership experience, advanced interpersonal and communication skills, and an internal drive to keep pushing forward. I want to be apart of the continual technological advancements that make the world a better, safer, and faster place for everyone. </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="about-me pt-4 pt-md-0">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                      <ul>
                        <li>
                        {this.state.city.map(content => {
                          return (
                            <p style={{fontFamily: "georgia", color: "#0078ff"}} key={content.id}>
                              {content.content}
                            </p>
                          );
                        })}
                        </li>
                        <li>
                        {this.state.resume.map(content => {
                          return (
                            <p style={{fontFamily: "georgia", color: "#0078ff"}} key={content.id}>
                              {content.content}
                            </p>
                          );
                        })}
                        </li>
                        <li>
                        {this.state.phone.map(content => {
                          return (
                            <p style={{fontFamily: "georgia", color: "#0078ff"}} key={content.id}>
                              {content.content}
                            </p>
                          );
                        })}
                        </li>
                        <li>
                        {this.state.email.map(content => {
                          return (
                            <p style={{fontFamily: "georgia", color: "#0078ff"}} key={content.id}>
                              {content.content}
                            </p>
                          );
                        })}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
